<template>
  <div class="black">
    <div class="table-footer" style="margin-bottom:15px;">
      <h2><span class="el-icon-arrow-left" style="cursor:pointer;" @click="$router.back()"></span>{{$route.query.account}}</h2>
      <el-button type="primary" @click="visible = true">添加黑名单</el-button>
    </div>
    <DragTable :header="header" :list-query="list">
      <template #ctl="{row}">
        <span class="word-color" @click="ctl(row)">移出</span>
      </template>
    </DragTable>
    <footer class="table-footer">
      <span></span>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="params.total"
      ></el-pagination>
    </footer>
    <go-dialog v-model="visible">
      <div>
        <el-input v-model="seller_id" placeholder="请输入账号"></el-input>
      </div>
      <footer class="table-footer">
        <el-button type="primary" @click="cancel">取消</el-button>
        <el-button type="primary" @click="addUser">确定</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      seller_id: '',
      visible: false,
      params: {
        id: '',
        page: 1,
        limit: 10,
        total: 0,
      },
      header: [
        { name: '序号', value: 'id' },
        { name: '卖家名', value: 'seller_name' },
        { name: '操作', slot: 'ctl' },
      ],
      list: {
        data: []
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$axios.get(`/marketCenter/yahoo/seller/blacklist/list`,{
        params: {
          redo_yahoo_auction_account_id:this.$route.query.id
        }
      })
      .then(res=>{
        this.list.data = res.data.data
        this.params.total = res.data.total
      })
    },
    async ctl(data) {
      const confirm = await this.$goDialog('是否将此卖家从黑名单移出？')
      if(!confirm) return
      this.$axios.delete(`/marketCenter/yahoo/seller/blacklist/delete/${data.id}`)
      .then(res=>{
        this.$message.success(res.msg)
        this.getList()
      })
      
    },
    handleSizeChange(val) {
      this.params.limit = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.getList()
    },
    cancel() {
      this.seller_id = ''
      this.visible = false
      this.getList()
    },
    addUser() {
      if(!this.seller_id) {
        this.$message.warning('请输入卖家账号')
        return
      }
      this.$axios.post(`/marketCenter/yahoo/seller/blacklist/save`,{
        redo_yahoo_auction_account_id: this.$route.query.id,
        seller_id: this.seller_id
      })
      .then(res=>{
        this.$message.success(res.msg)
        this.cancel()
      })
      
    },
  }
}
</script>
<style lang="scss" scoped>

</style>